
import { defineComponent, onMounted } from "vue";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "coop-auth",
  components: {},
  setup() {
    const route = useRoute();
    onMounted(() => {
      const query = route.query;
      if (query.referer) {
        let state = new Uint32Array(1);
        // https://developer.mozilla.org/fr/docs/Web/API/RandomSource/getRandomValues says that these number are strong enough to get crypto
        window.crypto.getRandomValues(state);
        localStorage.setItem("socialState", state[0].toString());
        const url = `${process.env.VUE_APP_API_URL}/accounts/social-auth?referer=${query.referer}&state=${state[0]}`;
        window.location.href = url;
      }
    });
  },
});
